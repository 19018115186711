import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import '../index.css';

const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
  
    useEffect(() => {
      const media = window.matchMedia(query);
  
      // Define the listener function
      const listener = (event: MediaQueryListEvent) => {
        setMatches(event.matches);
      };
  
      // Add the event listener
      media.addEventListener('change', listener);
  
      // Cleanup
      return () => media.removeEventListener('change', listener);
    }, [query]);
  
    return matches;
  };

const LaunchingSoon: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:648px)');

  return (
    <div className="relative w-full max-w-4xl mx-auto p-4">
        <div className="flex flex-col md:flex-row items-center justify-center">
            <motion.div 
                className="text-left md:text-right md:mr-8"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
            >
              {
                isMobile ? 
                <h1 className="text-7xl md:text-8xl text-center font-bold text-white leading-tight font-bebas">
                    GET READY TO HIRE THE <span className="text-[#214080]">BEST TALENTS</span>
                </h1>
                :
                <h1 className="text-7xl md:text-8xl font-bold text-white leading-tight font-bebas">
                    GET<br />READY<br />TO HIRE<br />THE <span className="text-[#214080]">BEST<br />TALENTS</span>
                </h1>
              }
                
            </motion.div>
            <motion.div 
                className="relative mt-8 md:mt-0"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
              <img 
                  src="https://viberesourcesolutions.com/images/rocket.png"
                  alt="Rocket launching from a laptop" 
                  className={`w-full h-auto`}
              />
              <motion.h2 
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-5xl md:text-6xl font-bold text-white drop-shadow-lg text-center font-bebas"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1, duration: 1 }}
              >
                  LAUNCHING<br />SOON
              </motion.h2>
            </motion.div>
        </div>
    </div>
  );
};

export default LaunchingSoon;
