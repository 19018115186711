import React from 'react';
import LaunchingSoon from './components/LaunchingSoon';
import './index.css';

const App: React.FC = () => {
  return (
    <div className="inset-0 bg-svg flex items-center justify-center min-h-screen">
      {/* <Header /> */}
      <main className="flex-grow">
        <LaunchingSoon />
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default App;
